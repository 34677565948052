import type { ComponentRef, PageRef } from '@wix/platform-editor-sdk';
import type { FlowEditorSDK, TFunction } from '@wix/yoshi-flow-editor';
import { APP_DEF_IDS } from '@wix/restaurants-consts';
import { createSectionDefinition } from './createSectionDef';
import { createWidgetDefinition } from './createWidgetDef';
import type { FedopsLogger } from './monitoring/FedopsLogger';

type PageData = {
  pageId: string;
  title: string;
  widgetId: string;
  desktopPresetId: string;
  mobilePresetId: string;
  pageUriSEO: string;
};

const addWidgetAsPage = async ({
  editorSDK,
  isEditorX,
  appDefId,
  pageData,
  pageTitle,
  shouldAddMenuItem = false,
  isResponsive = false,
  t,
}: {
  editorSDK: FlowEditorSDK;
  isEditorX: boolean;
  appDefId: string;
  pageData: PageData;
  pageTitle?: string;
  shouldAddMenuItem?: boolean;
  isResponsive?: boolean;
  t: TFunction;
}): Promise<PageRef> => {
  const { title, pageId, widgetId, desktopPresetId, mobilePresetId, pageUriSEO } = pageData;
  const pageRefPromise = await editorSDK.document.transactions.runAndWaitForApproval(
    'token',
    async () => {
      const pageRef = await editorSDK.pages.add('', {
        title: pageTitle ?? t(title),
        definition: {
          id: '',
          type: 'Page',
          ...(isEditorX
            ? {
                components: [
                  createSectionDefinition([
                    createWidgetDefinition({
                      appDefinitionId: appDefId,
                      widgetId,
                      desktopPresetId,
                      mobilePresetId,
                    }),
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  ]) as any,
                ],
              }
            : {}),
          data: {
            appDefinitionId: appDefId,
            managingAppDefId: APP_DEF_IDS.restaurants,
            tpaPageId: pageId,
            pageUriSEO,
          },
          breakpoints: isEditorX
            ? {
                type: 'BreakpointsData',
                id: 'breakpoints-k3sr3k4j',
                values: [
                  {
                    type: 'BreakpointRange',
                    id: 'breakpoints-kc1s7zda1',
                    min: 320,
                    max: 1000,
                  },
                  {
                    type: 'BreakpointRange',
                    id: 'breakpoints-kc1s7zda',
                    min: 320,
                    max: 750,
                  },
                ],
              }
            : undefined,
          componentType: 'mobile.core.components.Page',
        },
        shouldAddMenuItem,
        shouldNavigateToPage: isResponsive,
      });
      return pageRef;
    }
  );
  return pageRefPromise;
};

export const createAppPage = async ({
  editorSDK,
  isResponsive,
  isStudio,
  appDefId,
  pageData,
  pageTitle,
  fedopsLogger,
  shouldAddMenuItem = false,
  t,
}: {
  editorSDK: FlowEditorSDK;
  isResponsive: boolean;
  isStudio: boolean;
  appDefId: string;
  pageData: PageData;
  pageTitle?: string;
  fedopsLogger?: FedopsLogger;
  shouldAddMenuItem?: boolean;
  t: TFunction;
}): Promise<PageRef> => {
  const isEditorX = isResponsive && !isStudio;
  fedopsLogger?.createOLOPageStarted();
  const ppPageRef = await addWidgetAsPage({
    editorSDK,
    isEditorX,
    appDefId,
    pageData,
    pageTitle,
    shouldAddMenuItem,
    isResponsive,
    t,
  });
  if (!isEditorX) {
    await editorSDK.document.transactions.runAndWaitForApproval('', async () => {
      await addWidgetWithPresets({ editorSDK, ppPageRef, pageData, isStudio });
    });
  }
  fedopsLogger?.createOLOPageEnded();
  return ppPageRef;
};

const addWidgetWithPresets = async ({
  editorSDK,
  ppPageRef,
  pageData,
  isStudio,
}: {
  editorSDK: FlowEditorSDK;
  ppPageRef: PageRef;
  pageData: PageData;
  isStudio: boolean;
}): Promise<ComponentRef> => {
  if (isStudio) {
    await editorSDK.pages.navigateTo('', { pageRef: ppPageRef });
  }
  const { widgetId, desktopPresetId, mobilePresetId } = pageData;

  const children = await editorSDK.components.getChildren('', {
    componentRef: ppPageRef as ComponentRef,
  });

  const [classicSectionRef, studioSectionRef] = children;

  const widgetRef = await editorSDK.application.appStudioWidgets.addWidget('', {
    widgetId,
    layout: {
      // NOTE: docked is being used to stretch the widget to the full width of the page
      docked: {
        left: {
          px: 0,
          vw: 0,
        },
        right: {
          px: 0,
          vw: 0,
        },
      },
      height: 100,
      width: 980,
      x: 0,
      y: 0,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any,
    layouts: {
      componentLayout: {
        minHeight: {
          type: 'px',
          value: 280,
        },
        hidden: false,
        height: {
          type: 'auto',
        },
        type: 'ComponentLayout',
        width: {
          type: 'auto',
        },
      },
      itemLayout: {
        id: '',
        type: 'GridItemLayout',
        gridArea: {
          columnStart: 1,
          columnEnd: 2,
          rowStart: 1,
          rowEnd: 2,
        },
        alignSelf: 'stretch',
        justifySelf: 'stretch',
        margins: {
          left: {
            type: 'px',
            value: 0,
          },
          right: {
            type: 'px',
            value: 0,
          },
          top: {
            type: 'px',
            value: 0,
          },
          bottom: {
            type: 'px',
            value: 0,
          },
        },
      },
    },
    scopedPresets: {
      desktop: { layout: desktopPresetId, style: desktopPresetId },
      mobile: { layout: mobilePresetId, style: mobilePresetId },
    },
    installationType: 'closed',
    containerRef: isStudio ? studioSectionRef : classicSectionRef ?? (ppPageRef as ComponentRef),
  });
  return widgetRef;
};
