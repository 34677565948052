import type { TFunction } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { NAVIGATION_TOOLBAR_IDS } from 'root/appConsts/blocksIds';
import { NAVIGATION_WIDGET_DESIGN_PANEL_TABS } from 'root/appConsts/designPanelTabs';
import { configureWidgetDesign, disableElementsSelection } from 'root/editor/editor.utils';
import { getRole } from 'root/utils/utils';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder, editorSDK, flowAPI) => {
  widgetBuilder.set({
    displayName: 'Navigation',
    nickname: 'NavigationWidget',
  });

  const t = flowAPI.translations.t as TFunction;

  widgetBuilder.behavior().set({ removable: false, preventHide: true });

  widgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: t('design-gfpp.navigation.design'),
      actionType: 'design',
      actionId: '',
    })
    .set('design', {
      behavior: 'DEFAULT',
    });

  widgetBuilder.configureConnectedComponents(
    getRole(NAVIGATION_TOOLBAR_IDS.box),
    (componentBuilder) => {
      componentBuilder.behavior().set({ preventHide: true });
    }
  );

  configureWidgetDesign({
    widgetBuilder,
    title: t('design-panel.navigation.title'),
    tabs: NAVIGATION_WIDGET_DESIGN_PANEL_TABS,
    t,
  });

  const DISABLED_ELEMENTS = [
    NAVIGATION_TOOLBAR_IDS.menuDropdown,
    NAVIGATION_TOOLBAR_IDS.sectionTabs,
    NAVIGATION_TOOLBAR_IDS.navigationDivider,
  ];

  disableElementsSelection(widgetBuilder, DISABLED_ELEMENTS);
};
