import type { TFunction, WidgetBuilder } from '@wix/yoshi-flow-editor';

export const getRole = (componentId: string) => {
  return componentId.substring(1);
};

const EDIT_TEXT_BUTTON = 'mainAction2';

const configureTextMainActionSettingsGfpp = ({
  widgetBuilder,
  actionId,
  t,
  role,
}: {
  widgetBuilder: WidgetBuilder;
  actionId?: string;
  t: TFunction;
  role: string;
}) => {
  const mainGfppParams = actionId
    ? { label: t('app.gfpp.common.editText'), actionId }
    : { behavior: 'HIDE' };
  widgetBuilder.configureConnectedComponents(role, (componentBuilder) => {
    // @ts-expect-error
    componentBuilder.gfpp().set(EDIT_TEXT_BUTTON, mainGfppParams);
    componentBuilder.behavior().set({ dataEditOptions: 'TEXT_STYLE_ONLY' });
  });
};

export const configureWidgetTextDesignGfpp = ({
  widgetBuilder,
  actionId,
  t,
  textComponentIds,
}: {
  widgetBuilder: WidgetBuilder;
  actionId?: string;
  t: TFunction;
  textComponentIds: string[];
}) => {
  for (const textComponentId of textComponentIds) {
    configureTextMainActionSettingsGfpp({
      widgetBuilder,
      actionId,
      t,
      role: getRole(textComponentId),
    });
  }
};
