/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  AppManifest,
  ContextParams,
  EditorSDK,
  InitialAppData,
} from '@wix/platform-editor-sdk';

import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { setLightboxGfpp, setOloGfpp } from '../utils/utils';
import type { AppManifestBuilder, EditorScriptFlowAPI, TFunction } from '@wix/yoshi-flow-editor';
import { LIGHTBOX_IDS } from 'root/appConsts/consts';
import { createBILogger, createFedopsLogger, getManifestFedopsEvent } from './editor.utils';
import { getAppDescriptor } from './appDescriptor';
import { SPECS } from 'root/appConsts/experiments';

export const createAppManifest = async (
  options: any,
  editorSDK: EditorSDK,
  contextParams: ContextParams,
  flowAPI: EditorScriptFlowAPI
): Promise<AppManifest> => {
  const { translations, experiments } = flowAPI;
  const t = translations.t as TFunction;

  const biReporterService = createBILogger({ flowAPI, options: contextParams });
  const fedopsLogger = await createFedopsLogger({ editorSDK, flowAPI, biReporterService });
  const fedopsManifestLogger = getManifestFedopsEvent(fedopsLogger, contextParams.firstInstall);

  fedopsManifestLogger.started();
  const { appManifestBuilder, ...appData }: { appManifestBuilder: AppManifestBuilder } = options;

  const baseManifest = await fetchWidgetsStageData(appData as InitialAppData);

  // TODO: remove once editor fix is released, see related thread https://wix.slack.com/archives/CKDB50KE2/p1712043963671199
  for (const key in baseManifest.controllersStageData) {
    if (baseManifest.controllersStageData[key].default?.connections?.['*']) {
      delete baseManifest.controllersStageData[key].default?.connections?.['*'];
    }
  }

  setLightboxGfpp(appManifestBuilder, LIGHTBOX_IDS.itemModal, t('app.lightbox.itemModal.title'));
  const appManifest = appManifestBuilder.withJsonManifest(baseManifest).build();

  const appDescriptor = experiments.enabled(SPECS.myBusinessActions)
    ? await getAppDescriptor(editorSDK, t)
    : appManifest.appDescriptor;

  setOloGfpp(appManifest);

  fedopsManifestLogger.ended();
  return { ...appManifest, appDescriptor };
};
